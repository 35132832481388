<!--
 * @Description: 工单评价详情页面
 * @Author: 小广
 * @Date: 2019-06-12 17:09:25
 * @LastEditors: 小广
 * @LastEditTime: 2019-10-16 15:19:11
 -->
<template>
  <div class="orderEvaluateForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      submitText="回复"
      :footerShow="footerShow"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-block title="订单流转">
        <!-- 进度 -->
        <col2-progress :status="progressList" :percent="newProgress" />
      </col2-block>
      <col2-block title="订单信息">
        <el-row>
          <col2-item  label="订单编号" :text="evaluateFormData.serialNumber" />
        </el-row>
        <col2-item  label="订购服务" :text="evaluateFormData.categoryName" />
        <col2-item  label="用户姓名" :text="evaluateFormData.createUserName" />
        <col2-item  label="工单来源" :text="evaluateFormData.source" />
        <col2-item  label="联系电话" :text="evaluateFormData.createUserMobile" />
        <col2-item  label="下单时间" :text="evaluateFormData.createTime" />
        <col2-item  label="用户地址" :text="evaluateFormData.taskInfoAddress" />
        <col2-item  label="预约时间" :text="'无'" />
        <!-- <col2-item  label="服务商家" :text="info.shopName" /> -->
        <col2-item  label="订单状态">
          <span style="color:#a94442">{{orderStatusMap[evaluateFormData.status]}}</span>
        </col2-item>
      </col2-block>
    <col2-block title="订单内容">
      <col2-item :span="24" label="订单图片">
          <v-preview :imgs="picturesList" v-if="picturesList"></v-preview>
      </col2-item>
      <col2-item :span="24" label="工单视频" v-if="evaluateFormData.videos">
          <video controls :src="evaluateFormData.videos" width="300" height="300"></video>
      </col2-item>
      <col2-item :span="24" label="工单音频">
        <audio controls :src="item" v-for="(item, index) in evaluateFormData.audios" :key="index"></audio>
      </col2-item>
      <col2-item :span="24" label="订单描述" :text="evaluateFormData.description" />
    </col2-block>
      <!-- <col2-block title="订单操作">
        <col2-item label="接单人员" :text="operation.inceptUserName" />
        <col2-item label="接单时间" :text="operation.inceptTime" />
        <col2-item :span="24" label="接单反馈" :text="operation.inceptFeedback" />
        <col2-item label="开始处理" :text="operation.startDealUserName" />
        <col2-item label="开始时间" :text="operation.startDealTime" />
        <col2-item :span="24" label="开始处理反馈" :text="operation.startDealFeedback" />
        <col2-item label="处理完成" :text="operation.endDealUserName" />
        <col2-item label="完成时间" :text="operation.endDealTime" />
        <col2-item :span="24" label="处理结束反馈 " :text="operation.dealFeedback" />
      </col2-block> -->
      <!-- <col2-block v-if="dealInfoImageUrls.length" title="处理情况" >
        <col2-item :span="24" label="处理后图片">
          <div>
            <v-preview :imgs="dealInfoImageUrls"></v-preview>
          </div>
        </col2-item>
      </col2-block> -->
      <col2-block  title="用户评价" v-if="evaluateFormData.mEvaluationVList" >
        <div class="evaluate-wrapper" v-for="(item, index) in evaluateFormData.mEvaluationVList" :key="index">
          <div class="title">用户评价#{{index+1}}</div>
          <div class="rate-row">
            <div class="col2" v-for="(subItem, subIndex) in item.evaluationItem" :key="subIndex">
              <span class="rate-label">{{subItem.name}}</span>
              <rate
                :value="subItem.value"
                disabled
                text-color="#ff9900"
                class="rate">
              </rate>
            </div>
          </div>
          <div class="eva-cell" v-if="item.description"><span class="label">评价内容:</span><span>{{item.description}}</span></div>
          <div class="eva-cell" v-if="item.evaluationTime"><span class="label">评价时间:</span><span>{{item.evaluationTime}}</span></div>
          <!-- <div class="eva-cell" v-if="item.reply"><span class="label">回复评价:</span><span>{{item.reply}}</span></div>
          <div class="eva-cell" v-if="item.replyTime"><span class="label">回复时间:</span><span>{{item.replyTime}}</span></div> -->
        </div>
        <div></div>
      </col2-block>
      <col2-block v-if="!isInvoice && footerShow" title="处理反馈">
        <el-form-item>
          <v-textarea
            v-model="form.evaluate"
            placeholder="请输入回复内容"
          ></v-textarea>
        </el-form-item>
      </col2-block>
    </form-panel>
  </div>
</template>

<script>
import { queryURL, replyURL } from './api'
import { overbookTypeMap, payStatusMap } from './map'
import { orderStatusMap } from '../orderScheduling/map'
import { paymentChannelMap } from 'common/map'
import { Col2Detail, Col2Item, Col2Progress, Col2Block, TablePanel } from 'components/bussiness'
import { vPreview } from 'components/control'
import { Rate } from 'element-ui'

export default {
  name: 'OrderEvaluateForm',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    TablePanel,
    Col2Progress,
    vPreview,
    Rate
  },
  data () {
    return {
      overbookTypeMap,
      orderStatusMap,
      paymentChannelMap,
      payStatusMap,
      serialNumber: '',
      orderImageUrls: [],
      dealInfoImageUrls: [],
      form: {
        evaluate: '感谢您的支持，祝您生活愉快!'
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: replyURL,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      footerShow: false,
      progress: 0,
      info: {},
      content: {},
      operation: {},
      dealInfo: {},
      evaluateList: [],
      consignee: {},
      grantDetail: {},
      orderHeaders: [
        {
          prop: 'productName',
          label: '商品名称'
        },
        {
          prop: 'price',
          label: '单价'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'count',
          label: '购买数量'
        },
        {
          prop: 'shopName',
          label: '商家'
        }
      ],
      orderData: [],
      isInvoice: false,
      evaluateFormData: {}
    }
  },

  computed: {
    progressList () {
      return [
        {
          name: '下单时间',
          time: this.evaluateFormData.createTime
        },
        {
          name: '接单时间',
          time: this.evaluateFormData.inceptTime
        },
        {
          name: '开始处理',
          time: this.evaluateFormData.startDealTime
        },
        {
          name: '处理完成 ',
          time: this.evaluateFormData.endDealTime
        },
        {
          name: this.evaluateFormData.status == '8' ? '已关闭' : '已完成'
        }
      ]
    },
    newProgress () {
      const progressMap = {
        1: 20,
        2: 40,
        5: 60,
        6: 80,
        7: 100,// 已完成
      }
      let status = this.evaluateFormData.status
      switch (status) {
          case 3: // 超时未接收 = 待接收   
            status = 1
            break;
          case 4: //  超时未处理 = 待处理
            status = 2
            break;
          case 9: //  超时处理中 = 处理中
            status = 5
            break;
          case 8: // 已关闭
            status = 7
            break;
          default:
              break;
      }
      return progressMap[status]
      // return this.info.orderStatus ? progressMap[this.info.orderStatus] : 0
    },
    canReply () {
      // 取出来的是数字 需要转换下
      let canReply = this.$store.state.permission.replyApprise
      return canReply === 1
    },
    picturesList () {
      if (this.evaluateFormData.images) {
        return this.info.images.split("|")
      } else {
        return []
      }
    },
  },

  // POST

  created () {
    const { serialNumber, isInvoice } = this.$route.query
    this.isInvoice = isInvoice !== undefined
    this.serialNumber = serialNumber
    let title = this.isInvoice ? '订单详情' : '工单详情'
    this.$setBreadcrumb(title)
  },

  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$refs.formPanel.getData({ tid: id})
    }
  },

  methods: {
    update (data) {
      this.evaluateFormData = data
      this.evaluateFormData.audios = data.audios.length > 0 ? data.audios.split('|') : []
      if(this.evaluateFormData.mEvaluationVList) {
        this.evaluateFormData.mEvaluationVList.forEach(item => {
          item.evaluationItem = JSON.parse(item.evaluationItem)
        }) 
      }
      let { info, consignee, content, grantDetail, operation, dealInfo, evaluateList } = data
      this.content = content
      this.consignee = consignee
      this.grantDetail = grantDetail
      this.operation = operation
      this.dealInfo = dealInfo

      // 订单内容
      if (info) {
        info.expensesMoney = info.expensesMoney || '0.00'
        this.dealWithProgress(info)
        this.info = info
      }

      // 订单信息
      if (content && content.pictures) {
        let tempList = []
        content.pictures.forEach(item => {
          tempList.push({ src: item.url })
        })
        this.orderImageUrls = tempList
      }
      // this.orderData = content.products || []

      // 处理情况 pictures
      if (dealInfo && dealInfo.pictures) {
        let tempList = []
        dealInfo.pictures.forEach(item => {
          tempList.push({ src: item.url })
        })
        this.dealInfoImageUrls = tempList
      }

      // 处理回复按钮的显示 footerShow
      if (evaluateList && evaluateList.length) {
        let footerShow = false
        evaluateList.forEach(item => {
          // 订单状态是4 已评价
          item.quality = parseInt(item.quality)
          item.speed = parseInt(item.speed)
          if (item.isReply === '0' && (info && info.orderStatus === '4')) {
            footerShow = true
          }
        })
        // 再结合权限进行判断
        this.footerShow = footerShow && this.canReply
        if (evaluateList.length > 1) {
          evaluateList.reverse()
        }

        this.evaluateList = evaluateList
      }
    },

    // 处理进度条
    dealWithProgress (info) {
      let orderStatus = parseInt(info.orderStatus)
      switch (orderStatus) {
        case 1:
          this.progress = 20
          break
        case 2:
          this.progress = 60
          break
        case 3:
          this.progress = 80
          break
        case 4:
          this.progress = 100
          break
        case 5:
          this.progress = 100
          break
        case 6:
          this.progress = 40
          break
      }
    },

    submitBefore (data) {
      data.operationDes = this.dealInfo.feedback
      data.caseId = this.$route.query.id
      data.orderId = this.$route.query.id
      data.orderStatus = this.info.orderStatus
      data.taskMemo = this.dealInfo.feedback
      return true
    }
  }
}

</script>

<style lang="scss" scoped>
.orderEvaluateForm-wrapper {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }

  .evaluate-wrapper {
    padding-left: 168px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
      font-weight: 700;
    }
    .rate-row {
      display: flex;
      .col2 {
        display: flex;
        flex: 1;
        .rate-label {
          width: 70px;
        }
      }
    }
    .eva-cell {
      display: flex;
      margin: 10px 0;

      .label {
        width: 70px;
      }
    }
  }
}
</style>
